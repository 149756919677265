import React from 'react'
import cookie from 'js-cookie'

import SectionBlock from '../sectionBlock'
import JobBlockLoader from '../Loading/jobBlockLoader'
import { LANG_CODE_VI } from '../../helpers/language'
import { Slider } from '@microfrontends/react-components'
import { EVENT_UPDATE_USER } from '../constants'

const isMobile =
	typeof window !== 'undefined' ? window.innerWidth <= 768 : false
const ITEMS_PER_SLIDE = isMobile ? 4 : 3
const SLIDES_PER_VIEW = isMobile ? 1 : 3

/**
 * Integratde Job list with Slick slider
 * Data source (Optional): Call API to get PIO jobs
 * Use cases: Featured jobs, Recommendation jobs
 *
 * @param {*} WrappedComponent
 * @param {*} dataSource
 * @param {*} initJobData
 */
function withSlidableJobList<T>(
	WrappedComponent: React.ComponentType<T>,
	dataSource: Function,
	isRecoJobs: boolean = false,
	customSectionClass: string = '',
	noWrapper: boolean = false,
	recallDataSource = (_props: any, _prevProps: any) => false
) {
	return class withSlidableJobListComponent extends React.Component<
		any,
		any
	> {
		constructor(props: any) {
			super(props)
			const initJobData = this.props.jobs || []
			this.state = {
				jobs: initJobData,
				forceHide: !dataSource && initJobData.length == 0,
				isMobile: false,
				isBot: this.props.isBot
			}
		}

		retrieveData = async () => {
			let dataSourcePromise: Promise<any> | null = null
			const langCode = this.props.langCode || LANG_CODE_VI
			if (isRecoJobs) {
				const userId = this.props.user ? this.props.user.userId : null
				const lastViewJobId = this.getLastViewJobId(userId)
				dataSourcePromise = dataSource(
					lastViewJobId,
					userId || this.props.userIP,
					langCode,
					this.props.user?.accessToken || ''
				)
			} else {
				dataSourcePromise = dataSource(this.props)
			}

			try {
				const data: any[] = await dataSourcePromise

				if (data.length > 0) {
					this.setState({ jobs: data })
				} else {
					this.handleNoJobsFound()
				}

				if (this.props.onDataSourceComplete) {
					this.props.onDataSourceComplete(data)
				}
			} catch (err) {
				// TODO Log Error To Sentry
				this.handleNoJobsFound()

				if (this.props.onDataSourceComplete) {
					this.props.onDataSourceComplete([])
				}
			}
		}

		handleNoJobsFound() {
			this.setState({
				forceHide: true
			})
		}

		handleResize = () => {
			if (typeof window !== 'undefined') {
				if (window.innerWidth > 992) this.setState({ isMobile: false })
				else this.setState({ isMobile: true })
			}
		}

		getLastViewJobId(userId: number) {
			const lastViewCookie = cookie.get('VNW_LAST_JOB_SEEN')
			if (!!lastViewCookie) {
				const lastViewJobData =
					decodeURIComponent(lastViewCookie).split(',')
				const jobId = lastViewJobData[0]
				const userViewJob = lastViewJobData[1] || null

				const isAnonymous = !userId || userViewJob == '0'

				// not return job Id if logged in user is different than last viewed user
				if (!isAnonymous && Number(userId) !== Number(userViewJob)) {
					return null
				}

				// other than that case, return job Id
				return jobId
			}

			return null
		}

		buildLoadingSlides = () => {
			let loadingSlide: JSX.Element[] = []
			for (let i = 0; i < ITEMS_PER_SLIDE * SLIDES_PER_VIEW; i++) {
				loadingSlide.push(
					<div className='column is-12-mobile is-4-tablet' key={i}>
						{JobBlockLoader(i + 1)}
					</div>
				)
			}

			return (
				<div
					style={{ width: 'calc(100% + 1.5rem)' }}
					className='columns is-multiline col-has-space'
				>
					{loadingSlide}
				</div>
			)
		}

		// Return the structrue of slick slider from a list of jobs
		renderSlideContent = () => {
			const jobs = this.state.jobs
			let slidesContent: JSX.Element[] = []
			let jobsPerPage: JSX.Element[] = []
			let slideIndex = 0

			jobs.forEach((job: any, index: number) => {
				const isFinalIndex =
					(jobs.length <= ITEMS_PER_SLIDE &&
						index === jobs.length - 1) ||
					jobsPerPage.length === ITEMS_PER_SLIDE - 1

				jobsPerPage.push(
					<WrappedComponent
						key={`${job.jobId}-${index}`}
						isFinalIndex={isFinalIndex}
						isHotJob={job.isHot}
						title={job.title}
						company={job.company}
						logo={job.logo}
						url={job.url}
						locations={job.locations}
						langCode={this.props.langCode}
						isLogin={!!this.props.user?.userId}
						userInfo={this.props.user}
						accessToken={this.props.user?.accessToken || ''}
						{...job}
					/>
				)

				// Render the full column
				if ((index + 1) % ITEMS_PER_SLIDE === 0) {
					slidesContent.push(
						<div className='swiper-slide' key={++slideIndex}>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									gap: 16
								}}
							>
								{jobsPerPage}
							</div>
						</div>
					)
					jobsPerPage = []
				}

				// Render the last column
				if (index === jobs.length - 1 && jobsPerPage.length > 0) {
					slidesContent.push(
						<div className='swiper-slide' key={++slideIndex}>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									gap: 16
								}}
							>
								{jobsPerPage}
							</div>
						</div>
					)
				}
			})

			return slidesContent
		}

		updateUserState = async (_e: Event) => {
			if (dataSource) {
				this.retrieveData()
			}
		}

		async componentDidMount() {
			if (dataSource) {
				await this.retrieveData()
			}

			this.handleResize()
			window.addEventListener('resize', this.handleResize)
			window.addEventListener(EVENT_UPDATE_USER, this.updateUserState)
		}

		async componentDidUpdate(prevProps: any) {
			if (recallDataSource(this.props, prevProps)) {
				await this.retrieveData()
			}
		}

		async componentWillUnmount() {
			window.removeEventListener('resize', this.handleResize)
			window.removeEventListener(EVENT_UPDATE_USER, this.updateUserState)
		}

		renderContent() {
			const slidesContent = this.renderSlideContent()
			const { isBot } = this.state
			return (
				<div className='swiper-container'>
					{isMobile ? (
						<Slider
							id={`recommend-jobs-mobile`}
							slides={{ spaceBetween: 12 }}
							pagination={{ maxShow: 6, type: 'defaultNotArrow' }}
							loadingEnable={this.state.jobs.length === 0}
							loadingHeight={445}
							container={{ style: { width: '100%' } }}
							isRobot={isBot}
						>
							{slidesContent}
						</Slider>
					) : (
						<Slider
							id={`recommend-jobs`}
							slides={{ elementPerView: 3, spaceBetween: 16 }}
							pagination={{ maxShow: 8, elementPerPage: 2 }}
							loadingEnable={this.state.jobs.length === 0}
							loadingHeight={345}
							container={{ style: { width: '100%' } }}
							isRobot={isBot}
						>
							{slidesContent}
						</Slider>
					)}
				</div>
			)
		}

		render() {
			let sectionClass = this.props.noDots
				? ['sectionBlock_job-list']
				: ['sectionBlock_has-slider', 'sectionBlock_job-list']
			sectionClass.push(customSectionClass)
			if (this.state.forceHide) {
				return <></>
			}

			const contentNode = this.renderContent()

			return noWrapper ? (
				contentNode
			) : (
				<SectionBlock
					title={this.props.sectionTitle}
					sectionClass={sectionClass}
					ctaLink={this.props.ctaLink}
				>
					{contentNode}
				</SectionBlock>
			)
		}
	}
}

export default withSlidableJobList
