import { makeAPIRequest } from '../../services/apiClient'
import { utmHotJob, utmTopJob } from '../../helpers/utmTracking'
import { ELanguage } from '@microfrontends/react-components'
import { TUser } from '@microfrontends/app-shell-v2'

const apiUrl = process.env.FEATURED_JOBS_API_BASE
const baseApiUrl = process.env.API_DOMAIN

export default async function dataSource(language: ELanguage, user?: TUser) {
	let data: any[] = []

	try {
		const path = `${apiUrl}${
			user && user.userId
				? `?userId=${user.userId}&t=${String(Date.now())}`
				: ''
		}`
		data = await makeAPIRequest(path, 'get', language)
		if (!!data) {
			const jobs = data.map((item) => {
				let utmQueryString = ''

				if (item.typeTopJob == 2) {
					utmQueryString = utmHotJob()
				} else if (item.typeTopJob == 0) {
					utmQueryString = utmTopJob()
				}

				return {
					title: item.jobTitle ?? '',
					locations:
						language === ELanguage.VI
							? item.cityNames.split(', ')
							: item.cityNamesEN.split(', '),
					company: item.companyName ?? '',
					companyId: item.companyId ?? 0,
					companyUrl: item.companyUrl ?? '',
					logo: item.companyLogo ?? '',
					url:
						utmQueryString != ''
							? `${item.url}/?${utmQueryString}`
							: `${item.url}`,
					isHot: item.typeTopJob == 2 ?? false,
					jobId: item.id ?? 0,
					salary: item.prettySalary ?? '',
					jobLevel:
						language === ELanguage.VI
							? item.jobLevelName
							: item.jobLevelNameEN ?? '',
					jobLevelId: item.jobLevelId ?? 0,
					address: item.address ?? '',
					jobDescription: item.jobDescription ?? '',
					jobRequirement: item.jobRequirement ?? '',
					isAnonymous: item.isAnonymous ?? false
				}
			})

			if (jobs.length > 0 && user?.accessToken) {
				const jobIds = jobs.map((item) => item.jobId)

				const resSpecialJobs = await fetch(
					`${baseApiUrl}/api-gateway/v1.0/special-jobs`,
					{
						method: 'POST',
						mode: 'cors',
						headers: {
							'Content-Type': 'application/json',
							Authorization: `Bearer ${user.accessToken}`,
							'Accept-Language': language
						},
						body: JSON.stringify({
							jobId: jobIds
						})
					}
				)

				const specialJobs = await resSpecialJobs.json()
				if (specialJobs.data) {
					const formatJobs = jobs.map((item, idx) => {
						return {
							...item,
							...specialJobs.data[idx].attributes
						}
					})
					return formatJobs
				}
				return jobs
			} else {
				return jobs
			}
		}
		return null
	} catch (err) {
		console.error(err)
		return null
	}
}
