import dynamic from 'next/dynamic'
import DataSource from './dataSource'
import { TUser } from '@microfrontends/app-shell-v2'
import { memo } from 'react'
import { ELanguage } from '@microfrontends/react-components'
import withSlidableJobPopulation from '../HOCs/withSlidableJobList'

import { TJobBlock } from '../JobBlock/type'

const JobBlock = dynamic(() => import('../JobBlock'))

const FeaturedJobs = withSlidableJobPopulation<TJobBlock>(
	JobBlock,
	(props: { langCode: ELanguage; user: TUser }) =>
		DataSource(props.langCode, props.user),
	false,
	'section-featured-jobs'
)

export default memo(FeaturedJobs)
