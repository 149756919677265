import React, { PureComponent } from 'react'

interface IProps {
	width: number | string
	height: number | string
}

export class ContentLoader extends PureComponent<IProps> {
	constructor(props: IProps) {
		super(props)
	}

	render() {
		const { width, height } = this.props
		const style = { width, height }

		return <div className='placeholder-shimmer' style={style} />
	}
}
